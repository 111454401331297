*{
    margin:0;
    padding:0;
}

/* WebKit-based browsers */
::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: transparent; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Radius of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the scrollbar thumb on hover */
}

.selected{
    background-color:blue;
}

.sender{
    margin-right: 1rem;
    align-items: flex-end;
}

.reciever{
    margin-left: 1rem;
    align-items: flex-start;
   
}

.message_container {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width:100vw;
    font-size: 24px;
    color: #333;
}

/* Spinner styles */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}


